import {
  GCLID,
  VISIT_URL,
  UTM_MEDIUM,
  UTM_SOURCE,
  UTM_CONTENT,
  UTM_CAMPAIGN,
  UTM_TERM,
  BWLL_MKT_PRODUCT_INTEREST,
  GOOGLE_UTM_SOURCE,
} from '../constants'
import {
  MARKETING_SESSION_ID_COOKIE_KEY,
  MARKETING_UID_COOKIE_KEY,
  AUTHENTICATED_SESSION_COOKIE_KEY,
  AbstractCookieService,
  cookieService,
} from './CookiesService'
import { GuidService, guidService } from './guidService'
import { extractParamsFromUrl, paramsObjectToString } from '../helpers/urlFunctions'

export type OptionalParams =
  | typeof AUTHENTICATED_SESSION_COOKIE_KEY
  | typeof VISIT_URL
  | typeof UTM_MEDIUM
  | typeof UTM_SOURCE
  | typeof UTM_CAMPAIGN
  | typeof UTM_CONTENT
  | typeof UTM_TERM
  | typeof BWLL_MKT_PRODUCT_INTEREST
  | typeof GCLID

export type RequiredParams = typeof MARKETING_SESSION_ID_COOKIE_KEY | typeof MARKETING_UID_COOKIE_KEY

export type AllowedParams = OptionalParams | RequiredParams

export interface IParams {
  [MARKETING_SESSION_ID_COOKIE_KEY]: string
  [MARKETING_UID_COOKIE_KEY]: string
  [AUTHENTICATED_SESSION_COOKIE_KEY]?: string
  [VISIT_URL]?: string
  [UTM_MEDIUM]?: string
  [UTM_SOURCE]?: string
  [UTM_CAMPAIGN]?: string
  [UTM_CONTENT]?: string
  [UTM_TERM]?: string
  [BWLL_MKT_PRODUCT_INTEREST]?: string
  [GCLID]?: string
}

const ALLOWED_PARAMS: AllowedParams[] = [
  MARKETING_SESSION_ID_COOKIE_KEY,
  MARKETING_UID_COOKIE_KEY,
  AUTHENTICATED_SESSION_COOKIE_KEY,
  BWLL_MKT_PRODUCT_INTEREST,
  VISIT_URL,
  UTM_MEDIUM,
  UTM_SOURCE,
  UTM_CAMPAIGN,
  UTM_CONTENT,
  UTM_TERM,
  GCLID,
]

export class ParamsService {
  guidService: GuidService
  params: IParams
  cookieService: AbstractCookieService

  constructor(guidService: GuidService, cookieService: AbstractCookieService) {
    this.guidService = guidService
    this.cookieService = cookieService
    this.params = {
      [MARKETING_SESSION_ID_COOKIE_KEY]: this.guidService.getStoredGuid(MARKETING_SESSION_ID_COOKIE_KEY),
      [MARKETING_UID_COOKIE_KEY]: this.guidService.getStoredGuid(MARKETING_UID_COOKIE_KEY),
    }
  }

  /**
   * Initializes the service, extracting the incoming parameters and merging it with the existing ones stored as cookies
   * @param {string} url url of the website
   */
  init(url: string) {
    const existingParams = this.getParametersStoredAsCookies()
    const incomingParams = this.saveIncomingParamsToCookies(this.injectDefaultParamsToUrl(url))
    const mergedParams = {
      ...existingParams,
      ...incomingParams,
    }

    this.setParams(mergedParams)
  }

  getParams() {
    return this.params
  }

  getParam(key: AllowedParams) {
    return this.params[key]
  }

  renewParams() {
    this.reSaveParamsToCookies()
  }

  setParam(paramKey: AllowedParams, paramValue: string): void {
    if (ALLOWED_PARAMS.some(allowedParamKey => allowedParamKey === paramKey)) {
      this.params[paramKey] = paramValue
      this.reSaveParamsToCookies()
    }
  }

  unsetParam(paramKey: OptionalParams): void {
    this.params[paramKey] = undefined
    this.cookieService.removeCookie(paramKey)
  }

  formatUrlWithMarketingSiteSessionId = (url: string): string => {
    const marketingSiteCookie = this.getParam(MARKETING_SESSION_ID_COOKIE_KEY)
    if (!marketingSiteCookie) return url
    return url.replace(/{{mktg_site_session_id}}/, marketingSiteCookie)
  }

  private injectDefaultParamsToUrl(url: string) {
    const [urlRoot] = url.split('?')
    const storedVisitUrl = this.cookieService.getCookie(VISIT_URL)
    const storedVisitUrlParams = storedVisitUrl ? extractParamsFromUrl(storedVisitUrl) : null
    const newVisitUrlParams = this.sanitizeParams(extractParamsFromUrl(url)).reduce((params, [key, value]) => {
      if (value) params[key] = value
      return params
    }, {} as IParams)

    const urlParams = {
      ...storedVisitUrlParams,
      ...newVisitUrlParams,
    }

    urlParams[MARKETING_SESSION_ID_COOKIE_KEY] = this.guidService.getStoredGuid(MARKETING_SESSION_ID_COOKIE_KEY)

    urlParams[MARKETING_UID_COOKIE_KEY] = this.guidService.getStoredGuid(MARKETING_UID_COOKIE_KEY)

    if (urlParams[UTM_SOURCE] !== GOOGLE_UTM_SOURCE && urlParams[GCLID]) {
      delete urlParams[GCLID]
    }

    urlParams[VISIT_URL] = encodeURIComponent(`${urlRoot}?${paramsObjectToString(urlParams)}`)

    return `${urlRoot}?${paramsObjectToString(urlParams)}`
  }

  private setParams(newParams: IParams) {
    if (newParams) {
      Object.entries(newParams).forEach(([paramKey, paramValue]) => {
        this.setParam(paramKey as AllowedParams, paramValue)
      })
    }
  }

  private sanitizeParams(newParams: { [x: string]: string }) {
    return Object.entries(newParams).filter((entry): entry is [AllowedParams, string] =>
      ALLOWED_PARAMS.some(allowedParamKey => allowedParamKey === entry[0]),
    )
  }

  private saveIncomingParamsToCookies(url: string) {
    //Converting query string to key/value object
    const paramObj = extractParamsFromUrl(url)

    if (paramObj) {
      //Saving key/value pairs as cookies using default cookie settings
      this.sanitizeParams(paramObj).forEach(([key, paramValue]: [string, string | undefined]) => {
        if (typeof paramValue !== 'undefined') {
          return this.cookieService.setCookie({
            key,
            value: decodeURIComponent(paramValue),
            cookieAttributes: key === MARKETING_UID_COOKIE_KEY ? { expires: 365 } : undefined,
          })
        }
      })
    }

    return paramObj
  }

  private getParametersStoredAsCookies() {
    const allowedParamsCookieValues = ALLOWED_PARAMS.map(paramKey => this.cookieService.getCookie(paramKey))

    return allowedParamsCookieValues.reduce((paramMap, cookieValue, index) => {
      if (!cookieValue) {
        return paramMap
      }
      paramMap[ALLOWED_PARAMS[index]] = cookieValue
      return paramMap
    }, {} as IParams)
  }

  private reSaveParamsToCookies() {
    Object.entries(this.params).forEach(([key, paramValue]: [string, string | undefined]) => {
      if (typeof paramValue !== 'undefined')
        this.cookieService.setCookie({
          key,
          value: decodeURIComponent(paramValue),
          cookieAttributes: key === MARKETING_UID_COOKIE_KEY ? { expires: 365 } : undefined,
        })
    })
  }
}

export const paramsService = new ParamsService(guidService, cookieService)
